import { val } from "@uirouter/angularjs";

const params = new URLSearchParams(window.location.search)

export class privacyStatementService {
     /* @ngInject */
  constructor($http) {
    this.$http = $http
      //register an observer
  }
    async getPrivacyStatement(language) {
        const result = await this.$http.get( 'https://appgallery.services.xerox.com/api/apps/template-privacy-policy',{ headers: { 'Accept-Language': language } })
        return result;
    }
}
