/* --------------------------------------------------------------------------------------
session.service.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export class sessionService {
    /* @ngInject */
    constructor (sessionDataService, $window) {
      this.sessionDataService = sessionDataService
      this.isLocal =  $window.location.host.includes("localhost");
    }

    async getDeviceAuthSessionData() {
      if (this.hasOwnProperty('sessionAccessInfo'))
        return Promise.resolve(this.sessionAccessInfo)
      try {
        const resultArray = this.isLocal ? ["", ""] : await Promise.all([this.getDeviceUsername(), this.getAccessInfo()])
        this.sessionAccessInfo = { username: resultArray[0], accessParams: resultArray[1].returnValue}
        return this.sessionAccessInfo
      } catch (e) {
        console.error(e)
        return null
      }
    }

    async getDeviceUsername() {
      const parsedInfo = await this.getParsedSessionInfo()
      return xrxGetElementValue(parsedInfo, 'username')      
    }
    async getDeviceFromAddress() {
      const parsedInfo = await this.getParsedSessionInfo()
      return xrxGetElementValue(parsedInfo, 'from')  
    }
    async getParsedSessionInfo() {
      try{
        if (!this.parsedSessionInfo) {
          const sessionInfo = await this.getSessionInfoWrapper()
          this.parsedSessionInfo = xrxSessionParseGetSessionInfo(sessionInfo)
        }
        return Promise.resolve(this.parsedSessionInfo)
      }
      catch(error){
        console.error(error)
      }  
    }
    getSessionInfoWrapper() {
      return new Promise((resolve, reject) => {
        xrxSessionGetSessionInfo(
         this.sessionDataService.deviceUrl,
          (env, response) => resolve(response),
          (request, response, status) => reject({request, response, status}) // TODO: handle error
        )
      })
    }
    getAccessInfo() {
      return new Promise((resolve, reject) => {
        xrxWsSnmpGet(
          this.sessionDataService.deviceUrl,
          'public',
          '1.3.6.1.4.1.253.8.74.6.2.1.9.06.157.118.300',
          (env, response) => resolve(xrxWsSnmpParseGet(response)),
          (request, response, status) => reject({request, response, status}) // TODO: handle error
        )
      })
    }
    setSessionInfo(data) {
      return new Promise((resolve, reject) => {
        xrxSessionGetSessionInfo(
          this.sessionDataService.deviceUrl,
          data,
          (env, response) => resolve(response),
          (request, response, status) => reject({request, response, status}),
          30
        )
      })
    }
  }
  