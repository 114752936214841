/* --------------------------------------------------------------------------------------
   listSelectorPopup.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const listSelectorPopupComponent = {
    template: require('./listSelectorPopup.html'),
    controllerAs: 'vm',
    bindings: {
      display: '=',
      fieldName: '<',
      items: '<',
      model: '=',
      onClose: '&'
    },
    controller: class listSelectorPopupCtrl {
      /* @ngInject*/
      constructor(commonService) {
        this.commonService = commonService
      }
      $onInit() {
        this.screenSize = this.commonService.getScreenSize()
        angular.element('.table-wrapper').removeClass
      }
      async open() {
        if (!this.scrollRefreshed) {
          angular.element('#' + this.getTableId())
            .xrxtable('scroll', this.getTableHeight())
          this.scrollRefreshed = true
        }
        this.selected = angular.copy(this.model) 
        await this.commonService.updateView()
      }
      ok() {
        this.model = this.selected
        this.closePopup()
      }
      cancel() {
        this.selected = angular.copy(this.model) 
        this.closePopup()
      }
      closePopup() {
        this.display = false
        this.commonService.updateView()
        this.onClose()
      }
      selectItem(item) {
        this.selected = item
        this.commonService.updateView()
      }
      getTableHeight() {
        const maxHeight = this.screenSize == 7 ? 387 : 460
        const rowHeight = this.screenSize == 7 ? 73 : 68
        const length = this.items && this.items.length > 0 ? this.items.length : 3
        const totalHeight = length * rowHeight
        return Math.min(maxHeight, totalHeight)
      }
      getTableId() {
        return this.fieldName.replace(' ','-').toLowerCase() + '-popup-table'
      }
    }
  }
  