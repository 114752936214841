/* --------------------------------------------------------------------------------------
   workflowDetails.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const workflowDetailsComponent = {
  template: require("./workflowDetails.html"),
  controllerAs: "vm",
  controller: class workflowDetailsController {
    /* @ngInject */
    constructor(commonService, workflowService, errorService, $state, appSettings) {
      this.commonService = commonService;
      this.workflowService = workflowService;
      this.errorService = errorService;
      this.$state = $state;
      this.appSettings = appSettings
    }

    async $onInit() {

      this.screenSize = this.commonService.getScreenSize();
      this.loading = true;
      this.toggle = true;
      try {
        this.workFlowDetails = await this.workflowService.getWorkFlowDetails();     
      } catch (error) {
        this.loading = false;
        this.handleInitError(error);
      }
      if (this.workflowService.hasData()) {
        this.loading = false;
        this.commonService.updateView();
        this.updateScroll()
      } else {
        this.$state.go("scan");
      } 
    }

    handleInitError(error){
        let errorTypeByStatusCode  = this.errorService.getErrorByStatusCode(error.status);
        if(errorTypeByStatusCode == this.errorService.errorTypes.errorHasOccured){
          //no match by status code - throw custom error
          this.showCustomError(error);
        }
        else{
          this.errorType = errorTypeByStatusCode;
          this.onErrorClose =  this.errorService.getErrorCallback(this.errorType);
          this.commonService.updateView();
        }
    }

    showCustomError(error) {
      this.errorType = this.errorService.errorTypes.errorHasOccured;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      if (!!error.status && error.status == 500) {
        this.errorType.keys = ['ERROR_FETCHING_WORKFLOW_METADATA', ...this.errorType.keys];
      }
      this.isProcessing = false;
      this.commonService.updateView();
    }

    ok() {
      let result = false;
      if (this.metaDataText) {
        result = result || this.metaDataText.validateRequiredField();
      }
      if (this.metaDataEnum) {
        let required = this.metaDataEnum.validateRequiredField();
        result = result || required;
      }

      if (this.metaDataDate) {
        let required = this.metaDataDate.validateRequiredField();
        result = result || required;
      }
      if (this.metaDataNumber) {
        let required = this.metaDataNumber.validateRequiredField();
        result = result || required;
      }
      if (this.metaDataDecimal) {
        let required = this.metaDataDecimal.validateRequiredField();
        result = result || required;
      }
      if (this.metaDataEmail) {
        let required = this.metaDataEmail.validateRequiredField();
        let invalidEmail = this.metaDataEmail.isEmailInvalid;
        result = result || required || invalidEmail;
      }
      if (!result) {
        this.commonService.updateView();
        this.$state.go("scan");
      } else {
        this.refreshScroll();
        //this.cancel();
      }
    }

    previous() {
      this.workflowService.selectedWorkflow = null;
      this.$state.go('workflow');
    }
    async close(){
      this.$state.go("scan")
    }

    reset() {
      if (this.metaDataText) {
        this.metaDataText.reset();
      }
      if (this.metaDataNumber) {
        this.metaDataNumber.reset();
      }
      if (this.metaDataDecimal) {
        this.metaDataDecimal.reset();
      }
      if (this.metaDataEnum) {
        this.metaDataEnum.reset();
      }
      if (this.metaDataBoolean) {
        this.metaDataBoolean.reset();
      }
      if (this.metaDataEmail) {
        this.metaDataEmail.reset();
      }
      if (this.metaDataDate) {
        this.metaDataDate.reset();
      }
      angular.element('#reset-banner').xrxbanner('call')
      this.commonService.updateView();
    }

    updateScroll() {
      setTimeout(() => {
        angular.element("#workflow-details-container").xrxscrollable({ scroll: true });
        if (this.hasScroll()) {
          angular
            .element("#workflow-details-container")
            .xrxscrollable({ scroll: true });
          angular.element("#workflow-details-container .shadow").removeClass("hide");
        } else {
          angular.element("#workflow-details-container").xrxscrollable("destroy");
          angular.element("#workflow-details-container .shadow").addClass("hide");
        }
        this.commonService.updateView();
      }, 100);
    }

    hasScroll() {
      return this.screenSize === 7
        ? this.workFlowDetails.length * 69 > 319
        : this.workFlowDetails.length * 63 > 420;
    }

    refreshScroll() {
      angular.element('#workflow-details-container').xrxscrollable('refresh');
      this.commonService.updateView();
    }
  }
};
