/* --------------------------------------------------------------------------------------
   privacyStatementPopup.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const privacyStatementPopupComponent = {
    template: `
    <div xw-popup display="vm.display" id="privacy-popup" custom-action-bar="#privacy-popup-action-bar-{{vm.popupId}}">
        <div
  xw-action-bar
  id="privacy-popup-action-bar-{{vm.popupId}}"
  class="xrx-action-bar {{ vm.getPrimaryBackground() }}"
  word-wrap="true"
  button-right="#preview-continue-button"
>
<div class="xrx-action-bar-label">
<div class="action-bar-label-text">
  {{ 'PRIVACY.POPUP_TITLE' | translate }}
</div>
</div>
<button
    xw-button
    glyph="close"
    no-text
    class="xrx-action-bar-button-right xglicon xglsize-36"
    ng-click="vm.onClose()"
  >
  </button>
</div>
<div id="privacy-statement-scroll" xw-bounding-box>
<div ng-show="vm.loading === true">
<div class="center-text privacy-spinner">
  <div
    class="center-activity"
    xw-activity
    widget-size="xrx-small"
    theme="{{vm.appSettings.primaryColor}}"
  ></div
  ><span translate>LOADING</span>
</div>
</div>
<pre class="privacy-content-container" display="!vm.loading">
            {{vm.content}}
                </pre>
</div>
    </div>
    `,
    bindings: {
        display: '<',
        loading: '<',
        content: '<',
        popupId:'<',
        onClose: '&'
    },
    controllerAs: 'vm'
  }