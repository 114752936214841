/* --------------------------------------------------------------------------------------
  metaDataNumber.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const metaDataNumberComponent = {
    template: require("./metaDataNumber.html"),
    bindings: {
        display: "=",
        data: "<",
        parentevent: '='
    },
    controllerAs: "vm",
    controller: class metaDataNumberController {
        /* @ngInject */
        constructor(commonService, workflowService, $state) {
            this.commonService = commonService;
            this.workflowService = workflowService;
            this.displayJobSplitPopup = false;
            this.$state = $state;
            this.isRequired = false;
        }

        $onInit() {
            this.metadataType = this.data.type.toLowerCase();
            this.parentevent = {};
            this.setBindings();
            //this.screenSize = this.commonService.getScreenSize();
            this.isBlur = true;
            this.isReset = false;
            this.showNumPad = false;
            //this.initKeypad();
        }

        openNumberPadPopup() {
            this.showNumPad = true;
            this.initKeypad();
        }

        openJobSplitPopup() {
            this.displayJobSplitPopup = true;
            this.commonService.updateView();
        }
        closeJobSplitPopup() {
            this.displayJobSplitPopup = false;
            this.commonService.updateView();
        }

        onOk() {
            this.data.value = parseInt(this.jobPriorityType, 10);
            if (this.data.value) {
                this.isRequired = false;
            }
            this.showNumPad = false;
        }

        onCancel() {
            this.showNumPad = false;
        }

        showEnterNumberBanner() {
            angular.element('#enter-number-banner').xrxbanner('open');
        }
        showMaxNumberBanner() {
            angular.element('#maximum-number-banner').xrxbanner('open');
        }

        initKeypad() {
            setTimeout(() => {
                angular.element('#job-split-data-count-input').xrxkeyboard({
                    initialPlaceholder: "0",
                    layout: 'xrxNum',
                    maxLength: 8,
                    theme: 'blue',
                    appendLocally: false,
                    appendTo: '#job-split-data-count-keypad',
                    alwaysOpen: true,
                    backgroundSlide: false,
                    beforeVisible: (event, keyboard, el) => {
                        angular.element(".xrx-keyboard").css("position", "relative");
                        angular.element(".xrx-num-accept").css("visibility", "hidden");
                        angular.element(".xrx-num-cancel").css("visibility", "hidden");
                        angular.element(".xrx-keyboard").css("box-shadow", "none");
                        angular.element(".xrx-keyboard").css("border-top", "none");
                        angular.element(".xrx-keyboard").css("transition", "width 0s");
                        angular.element(".xrx-keyboard").css("padding-top", "0");
                        angular.element(".xrx-keyboard").css("padding-bottom", "0");
                    },
                    change: (event, keyboard, el) => {
                        this.jobPriorityType = angular.element("#job-split-data-count-input").val()
                        if (this.isBlur) {
                            this.isBlur = false;
                            if (this.isReset) {
                                this.jobPriorityType = this.jobPriorityType.substring(0, 1);
                                this.isReset = false;
                            }
                        }
                    }
                });
                this.commonService.updateView();
            });
        }
        deleteKeypad() {
            if (angular.element("#job-split-data-count-input").getkeyboard()) {
                angular.element("#job-split-data-count-input").getkeyboard().destroy();
            }
        }
        removeError() {
            this.error = null
            this.$scope.$apply()
        }
        onBlur() {
            this.isBlur = true;
            this.commonService.updateView();
        }

        setBindings() {
            this.parentevent.ctrl = this;
            this.parentevent.reset = this.resetNumberType;
            this.parentevent.validateRequiredField = this.validateRequiredField;
        }

        resetNumberType() {
            this.ctrl.isRequired = false;
            this.ctrl.data.value = '';
            this.ctrl.commonService.updateView();
        }

        validateRequiredField() {
            if (this.ctrl.data.isRequired && (!this.ctrl.data.value || this.ctrl.data.value == '')) {
                this.ctrl.isRequired = true;
            }
            return this.ctrl.isRequired;
        }
    }
};
