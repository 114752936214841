/* --------------------------------------------------------------------------------------
   workflow.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const workflowComponent = {
  template: require("./workflow.html"),
  controllerAs: "vm",
  controller: class workflowController {
    /* @ngInject */
    constructor(
      appSettings,
      commonService,
      jobStatusService,
      printService,
      deviceInfoService,
      errorService,
      workflowService,
      gcpService,
      $state,
      moment,
    ) {
      this.appSettings = appSettings;
      this.commonService = commonService;
      this.jobStatusService = jobStatusService;
      this.printService = printService;
      this.deviceInfoService = deviceInfoService;
      this.errorService = errorService;
      this.workflowService = workflowService;
      this.gcpService = gcpService;
      this.$state = $state;
      this.moment = moment;
    }

    async $onInit() {
      try {
        this.screenSize = this.commonService.getScreenSize();

        if (this.screenSize === 10) {
          this.emailMaxInScreen = 7;
        } else {
          this.emailMaxInScreen = 5;
        }

        this.loading = true;
        this.commonService.updateView();

        this.workflows = [];
        try{
          this.workflows = await this.workflowService.getWorkflows();
        }
        catch(error){
          this.handlePwmError();
        }
        this.updateScroll();
      } catch (error) {
        this.handleInitError(error);
      } finally {
        if (this.workflows && this.workflows.length === 1) {
          this.selectedWorkflow = this.workflows[0];
          this.ok();
        } else {
          this.loading = false;
          await this.commonService.updateView();
        }
      }
    }

    handleInitError(error){
        let errorTypeByStatusCode  = this.errorService.getErrorByStatusCode(error.status);
        if(errorTypeByStatusCode == this.errorService.errorTypes.errorHasOccured){
          //no match by status code - throw custom error
          this.showCustomError(error);
        }
        else{
          this.errorType = errorTypeByStatusCode;
          this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
          this.commonService.updateView();
        }
    }

    showCustomError(error) {
      this.errorType = this.errorService.errorTypes.errorHasOccured;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      if (!!error.status && error.status == 500) {
        this.errorType.keys = ['ERROR_FETCHING_WORKFLOWS', ...this.errorType.keys];
      }
      this.isProcessing = false;
      this.commonService.updateView();
    }
    handlePwmError(){
      this.errorType = this.errorService.errorTypes.cannotConnectWithApi;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType)
      this.commonService.updateView()
    }

    async ok() {
      if(this.selectedWorkflow){
        this.workflowService.selectedWorkflow = this.selectedWorkflow;
        this.$state.go("workflowDetails");
      }
      else{
        await this.openSelectWorkflowBanner();
      }

    }

    async previous() {
      this.workflowService.selectedWorkflow = null;
      this.$state.go("project");
    }

    async close(){
      this.$state.go("scan")
    }

    async openSelectWorkflowBanner() {
      angular.element('#select-workflow-banner').xrxbanner('call')
    }

    updateScroll() {
      setTimeout(() => {
        angular.element("#workflow-list-container").xrxscrollable({ scroll: true });
        if (this.hasScroll()) {
          angular
            .element("#workflow-list-container")
            .xrxscrollable({ scroll: true });
          angular.element("#workflow-list-container .shadow").removeClass("hide");
        } else {
          angular.element("#workflow-list-container").xrxscrollable("destroy");
          angular.element("#workflow-list-container .shadow").addClass("hide");
        }
        this.commonService.updateView();
      }, 100);
    }

    hasScroll() {
      return this.screenSize === 7
        ? this.workflows.length * 69 > 319
        : this.workflows.length * 63 > 420;
    }

    selectPreset(item) {
      if (this.selectedWorkflow && this.selectedWorkflow.workflowId === item.workflowId) {
        this.selectedWorkflow = undefined;
      } else {
        this.selectedWorkflow = item;
      }
      this.commonService.updateView();
    }

    get checkDisableOkButton() {
      return !this.selectedWorkflow;
    }
  }
}
