/* --------------------------------------------------------------------------------------
   project.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const projectComponent = {
  template: require('./project.html'),
  controllerAs: 'vm',
  controller: class projectController {
    /* @ngInject */
    constructor (
      appSettings,
      commonService,
      jobStatusService,
      printService,
      deviceInfoService,
      errorService,
      projectService,
      gcpService,
      workflowService,
      persistService,
      sessionService,
      $state,
      moment
    ) {
      this.appSettings = appSettings
      this.commonService = commonService
      this.jobStatusService = jobStatusService
      this.printService = printService
      this.deviceInfoService = deviceInfoService
      this.errorService = errorService
      this.projectService = projectService
      this.gcpService = gcpService
      this.persistService = persistService
      this.workflowService = workflowService
      this.sessionService = sessionService
      this.$state = $state
      this.moment = moment
      this.screenSize = this.commonService.getScreenSize()
    }

    async $onInit () {
      try {
        this.loading = true
        this.commonService.updateView()
        this.projects = []
        try{
          this.projects = await this.projectService.getProjects()
        }
        catch(error){
          this.loading = false
          this.handlePwmError();
        }
        this.sessionAccessInfo = await this.sessionService.getDeviceAuthSessionData();
        this.updateScroll()
      } catch (error) {
        this.handleInitError(error)
      } finally {
        if (this.projects && this.projects.length === 1) {
          this.selectedProject = this.projects[0]
          this.ok()
        } else {
          this.loading = false
          await this.commonService.updateView()
        }
      }
    }
    handleInitError (error) {
      let errorTypeByStatusCode = this.errorService.getErrorByStatusCode(
        error.status
      )
      if (
        errorTypeByStatusCode == this.errorService.errorTypes.errorHasOccured
      ) {
        //no match by status code - throw custom error
        this.showCustomError(error)
      } else {
        this.errorType = errorTypeByStatusCode
        this.onErrorClose = this.errorService.getErrorCallback(this.errorType)
        this.commonService.updateView()
      }
    }
    handlePwmError(){
      this.errorType = this.errorService.errorTypes.cannotConnectWithApi;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType)
      this.commonService.updateView()
    }

    showCustomError (error) {
      this.errorType = this.errorService.errorTypes.errorHasOccured
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType)
      if (!!error.status && error.status == 500) {
        this.errorType.keys = [
          'ERROR_FETCHING_PROJECTS',
          ...this.errorType.keys
        ]
      }
      this.isProcessing = false
      this.commonService.updateView()
    }

    async ok () {
      if (!this.selectedProject) {
        this.showSelectProjectBanner()
      } else {
        this.loading = true;
        try{
          this.workflowService.selectedProject = this.selectedProject;
          try{
            await this.gcpService.postSession(
              this.selectedProject.projectId,
              this.gcpService.appId,
              this.gcpService.deviceSN,
              this.sessionService.sessionAccessInfo.username ?? "",
              this.gcpService.serverUrl,
              this.gcpService.apiKey
            )
          }
          catch(error){
            if(error.status !== 401){
              this.handlePwmError();
            }
            else{
              throw error;
            }

          }
          this.workflowService.showSelectWorkflow = true
          this.loading = false;
          this.$state.go('workflow')
        }
        catch (error){
          this.loading = false;
          this.handleInitError(error);
        }


      }
    }

    async cancel () {
      this.$state.go('scan')
    }

    updateScroll () {
      setTimeout(() => {
        angular
          .element('#project-list-container')
          .xrxscrollable({ scroll: true })
        if (this.hasScroll()) {
          angular
            .element('#project-list-container')
            .xrxscrollable({ scroll: true })
          angular.element('#project-list-container .shadow').removeClass('hide')
        } else {
          angular.element('#project-list-container').xrxscrollable('destroy')
          angular.element('#project-list-container .shadow').addClass('hide')
        }
        this.commonService.updateView()
      }, 100)
    }

    hasScroll () {
      return this.screenSize === 7
        ? this.projects.length * 69 > 319
        : this.projects.length * 63 > 420
    }

    selectPreset (item) {
      if (
        this.selectedProject &&
        this.selectedProject.projectId === item.projectId
      ) {
        this.selectedProject = undefined
      } else {
        this.selectedProject = item
      }
      this.commonService.updateView()
    }

    showSelectProjectBanner () {
      angular.element('#select-project-banner').xrxbanner('call')
    }
  }
}
