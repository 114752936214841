/* --------------------------------------------------------------------------------------
   scan.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const scanComponent = {
  template: require('./scan.html'),
  controllerAs: 'vm',
  controller: class scanController {
    /* @ngInject */
    constructor (
      appSettings,
      commonService,
      jobStatusService,
      printService,
      deviceInfoService,
      errorService,
      scanScreenService,
      workflowService,
      persistService,
      $state,
      $translate,
      moment
    ) {
      this.appSettings = appSettings
      this.commonService = commonService
      this.jobStatusService = jobStatusService
      this.printService = printService
      this.deviceInfoService = deviceInfoService
      this.errorService = errorService
      this.scanScreenService = scanScreenService
      this.workflowService = workflowService
      this.persistService = persistService
      this.$state = $state
      this.$translate = $translate
      this.moment = moment
    }

    async $onInit () {
      this.loading = true
      this.screenSize = this.commonService.getScreenSize()
      this.isPrintFinished = false
      this.commonService.updateView()
      this.hasWorkflow = await this.workflowService.hasWorkflow();
      try {
        await this.scanScreenService.setOptions(
          this.appSettings.saveScanSettings
        )
        const lighterLabel = await this.$translate('LIGHTER')
        const darkerLabel = await this.$translate('DARKER')
        const normalLabel = await this.$translate('NORMAL')

        this.lightenDarkenLabels = {
          '-3': `${lighterLabel} -3`,
          '-2': `${lighterLabel} -2`,
          '-1': `${lighterLabel} -1`,
          0: normalLabel,
          1: `${darkerLabel} +1`,
          2: `${darkerLabel} +2`,
          3: `${darkerLabel} +3`
        }

        angular.element('#lighten-darken-slider').xrxslider({
          customFeedbackLabels: this.lightenDarkenLabels,
          widgetSize: 'xrx-small',
          customChannel: true,
          range: false,
          min: -3,
          max: 3,
          showTicks: true,
          slide: (event, ui) => this.onChangeLightenDarken()
        })

        angular
          .element('#lighten-darken-slider')
          .xrxslider('value', this.scanScreenService.lightenDarken)

        this.loading = false
        this.commonService.updateView()
      } catch (error) {
        this.errorType = error.titleKey
          ? error
          : this.errorService.getErrorByStatusCode(error.status)
        this.onErrorClose = this.errorService.getErrorCallback(this.errorType)
        this.commonService.updateView()
      } finally {
        this.refreshScroll()
        this.changeSelectors()
      }
    }

    async ok () {
      try {
        if (this.isEmailInvalid()) {
          return;
        }
        if(!this.hasWorkflow){
          this.showSelectWorkflowBanner()
          return;
        }
        if (this.appSettings.saveScanSettings) {
          this.scanScreenService.saveScanSettings()
        }
        this._scan()
      } catch (error) {
        this.handleError(error)

        return
      }
    }

    async _scan () {
      try {
        this.isProcessing = true
        this.processingLabel = 'SCANNING'
        this.commonService.updateView()
        await this.scanScreenService.scan()
        this.isProcessing = true

        this.commonService.updateView()

        this.processingLabel = 'PROCESSING'
        if (this.scanScreenService.buildJob) {
          this.isProcessing = false
          this.scanScreenService.showBuildJobAlert = true
        } else {
          this.isProcessing = false
          this.finishJob()
        }
      } catch (error) {
        this.handleError(error)
      }
    }

    async finishJob () {
      try {
        this.isProcessing = true
        this.processingLabel = 'PROCESSING_AND_SENDING_INFO'
        await this.scanScreenService.confirmUpload()
        // Print if print settings is on
        if (this.scanScreenService.printSettings) {
          this.isProcessing = true
          this.processingLabel = 'PRINTING_CONFIRMATION'
          this.commonService.updateView()
          await this.printWithTimeout();
          this.completeWorkflow()
          this.commonService.updateView()
        } else {
          this.completeWorkflow()
        }
      } catch (error) {
        this.handleError(error)
      }
    }

    async preview () {
      try {
        if (this.isEmailInvalid()) {
          return
        }

        if (this.appSettings.saveScanSettings) {
          this.scanScreenService.saveScanSettings()
        }

        this.isProcessing = true
        this.processingLabel = 'SCANNING'
        this.commonService.updateView()
        await this.scanScreenService.scan()

        this.processingLabel = 'PROCESSING'
        this.commonService.updateView()
        this.isProcessing = false
        this.commonService.updateView()
        await this.scanScreenService.completeUpload()
        await this.scanScreenService.pageCount()
        this.scanScreenService.showSelectPreviewPopup = true
        this.commonService.updateView()
      } catch (error) {
        this.handleError(error)
      }
    }

    async previewBuildJob () {
      this.isProcessing = true
      this.processingLabel = 'PROCESSING'
      this.commonService.updateView()
      await this.scanScreenService.completeUpload()
      this.count = await this.scanScreenService.pageCount()
      this.isProcessing = false
      this.commonService.updateView()
      this.scanScreenService.showSelectPreviewPopup = true
    }

    async onPreviewProceed () {
      const spinnerLabel = this.getSpinnerLabelAfterPreview()
      this.processingLabel = spinnerLabel[0]
      this.isProcessing = true
      this.commonService.updateView()

      try {
        this.isProcessing = true
        this.processingLabel = 'PROCESSING_AND_SENDING_INFO'
        await this.scanScreenService.confirmUpload()
        this.scanScreenService.showSelectPreviewPopup = false
        this.scanScreenService.showBuildJobAlert = false
        this.commonService.updateView()
        //this.scanScreenService.previewSettings ? this.previewBuildJob() :
        this.completeWorkflow()
      } catch (error) {
        return this.handleError(error)
      }
    }

    getSpinnerLabelAfterPreview () {
      if (this.scanScreenService.printSettings)
        return ['PRINTING_CONFIRMATION', false]
      if (this.scanScreenService.emailSettings)
        return ['ON_WORKFLOW_COMPLETE', true]
      return ['PROCESSING', false]
    }

    completeWorkflow () {
      this.isProcessing = false
      this.commonService.updateView()
      this.workflowCompleteLabel = 'GCP_RECEIVED_YOUR_INFO'
      if (
        this.scanScreenService.emailSettings &&
        this.scanScreenService.emailAddress
      ) {
        this.workflowCompleteSubtitle = 'CONFIRMATION_EMAIL_SENT_TO'
      }
      this.showWorkflowCompleteAlert = true
      this.commonService.updateView()
      setTimeout(() => {
        angular
          .element('.xrx-activity-indicator')
          .xrxactivityindicator('complete')
      }, 200)
      if (this.scanScreenService.buildJob) {
        this.persistService.setString(
          this.appSettings.buildJobScanProfileId,
          null
        )
      }
    }

    onWorkflowCompleteAlertClose () {
      this.showWorkflowCompleteAlert = false
      this.workflowCompleteLabel = null
      this.workflowCompleteSubtitle = null
    }

    async reset () {
      await this.scanScreenService.setDefaults(
        this.appSettings.saveScanSettings
      )
      this.commonService.updateView()
      this.changeSelectors()
      angular
        .element('#lighten-darken-slider')
        .xrxslider('value', this.scanScreenService.lightenDarken)
    }

    changeSelectors () {
      this.changeOutputColorSelector()
      this.changeTwoSidedScanSelector()
      this.changeOriginalOrientationSelector()
      this.changeOriginalSizeSelector()
      this.changeOriginalTypeSelector()
      this.changeResolutionSelector()
    }

    isEmailInvalid () {
      const re = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      if (this.scanScreenService.emailSettings) {
        if (!this.scanScreenService.emailAddress) {
          this.showEnterEmailAddressBanner()
          return true
        }
        if (
          this.commonService.emailHasInvalidChars(
            this.scanScreenService.emailAddress
          )
        ) {
          this.showEmailForbiddenCharsBanner()
          return true
        }
        if (!re.test(this.scanScreenService.emailAddress)) {
          this.showInvalidEmailAddressBanner()
          return true
        }
      }
      return false
    }

    handleError (error) {
      this.errorType = this.errorService.errorTypes.errorHasOccured
      //if error has a status code, it is a network error message; get app error by status code
      if (error?.status) {
        this.errorType = this.errorService.getErrorByStatusCode(error.status)
      }
      //if error has a title Key, it is an app error message
      else if (error?.titleKey) {
        this.errorType = error
      }
      this.isProcessing = false
      this.persistService.setString(this.appSettings.buildJobScanProfileId, null);
      this.scanScreenService.scanProfileId = null;
      this.commonService.updateView()
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType)
      this.commonService.updateView()
    }

    selectOutputColor (value) {
      this.scanScreenService.outputColor =
        this.scanScreenService.outputColorOptions[value.index].key
      this.scanScreenService.outputColorText = value.label
      this.scanScreenService.outputColorIndex = value.index
      this.commonService.updateView()

      this.changeOutputColorSelector()
    }

    changeOutputColorSelector () {
      angular
        .element('#output-color-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#output-color-selector-menu li')
        .eq(this.scanScreenService.outputColorIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    selectTwoSidedScan (value) {
      this.scanScreenService.twoSidedScan =
        this.scanScreenService.twoSidedScanOptions[value.index].key
      this.scanScreenService.twoSidedScanText = value.label
      this.scanScreenService.twoSidedScanIndex = value.index
      this.commonService.updateView()

      this.changeTwoSidedScanSelector()
    }

    changeTwoSidedScanSelector () {
      angular
        .element('#two-sided-scan-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#two-sided-scan-selector-menu li')
        .eq(this.scanScreenService.twoSidedScanIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    selectOriginalType (value) {
      this.scanScreenService.originalType =
        this.scanScreenService.originalTypeOptions[value.index].key
      this.scanScreenService.originalTypeImage =
        this.scanScreenService.originalTypeOptions[value.index].img
      this.scanScreenService.originalTypeText = value.label
      this.scanScreenService.originalTypeIndex = value.index
      this.commonService.updateView()

      this.changeOriginalTypeSelector()
    }

    changeOriginalTypeSelector () {
      angular
        .element('#original-type-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#original-type-selector-menu li')
        .eq(this.scanScreenService.originalTypeIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    selectOriginalSize (value) {
      const selectedSize =
        this.scanScreenService.originalSizeOptions[value.index]
      this.scanScreenService.originalSize = selectedSize.key
      this.scanScreenService.originalSizeText = selectedSize.value
      this.scanScreenService.originalSizeOrientation = selectedSize.orientation
      this.scanScreenService.originalSizeIndex = value.index
      this.commonService.updateView()

      this.changeOriginalSizeSelector()
    }

    changeOriginalSizeSelector () {
      angular
        .element('#original-size-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#original-size-selector-menu li')
        .eq(this.scanScreenService.originalSizeIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    selectResolution (value) {
      this.scanScreenService.resolution =
        this.scanScreenService.resolutionOptions[value.index].key
      this.scanScreenService.resolutionText = value.label
      this.scanScreenService.resolutionValue =
        this.scanScreenService.resolutionOptions[value.index].resolution
      this.scanScreenService.resolutionIndex = value.index
      this.commonService.updateView()

      this.changeResolutionSelector()

      // TODO: If required to show banner for high resolution then comment out below lines
      // if (this.scanScreenService.resolution === "600") {
      //   angular.element("#high-resolution-banner").xrxbanner("open");
      // }
    }

    changeResolutionSelector () {
      angular
        .element('#resolution-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#resolution-selector-menu li')
        .eq(this.scanScreenService.resolutionIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    selectOriginalOrientation (value) {
      this.scanScreenService.originalOrientation =
        this.scanScreenService.originalOrientationOptions[value.index].key
      this.scanScreenService.originalOrientationText = value.label
      this.scanScreenService.originalOrientationIndex = value.index
      this.commonService.updateView()
    }

    changeOriginalOrientationSelector () {
      angular
        .element('#original-orientation-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#original-orientation-selector-menu li')
        .eq(this.scanScreenService.originalOrientationIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    getOrientationGlyph (orientation) {
      switch (orientation) {
        case 'L':
          return 'glyphicon-short-edge-feed'
        case 'P':
          return 'glyphicon-long-edge-feed'
        default:
          return null
      }
    }

    showInvalidEmailAddressBanner () {
      angular.element('#email-not-valid-format-banner').xrxbanner('call')
    }

    showEmailForbiddenCharsBanner () {
      angular.element('#email-forbidden-chars-banner').xrxbanner('call')
    }

    showEnterEmailAddressBanner () {
      angular.element('#enter-email-address-banner').xrxbanner('call')
    }

    showSelectWorkflowBanner(){
      angular.element('#scan-select-workflow-banner').xrxbanner('call')
    }

    refreshScroll () {
      if (angular.element('#scan-scanSettings-container')) {
        angular.element('#scan-scanSettings-container').xrxscrollable('refresh')
        this.commonService.updateView()
      } else {
        setTimeout(() => {
          this.refreshScroll()
        }, 300)
      }
    }

    onChangeLightenDarken () {
      setTimeout(() => {
        const lightenDarken = angular
          .element('#lighten-darken-slider')
          .xrxslider('value')
        this.scanScreenService.lightenDarken = lightenDarken
      }, 500)
    }

    get disableOk () {
      return (
        !this.workflowService.selectedWorkflow ||
        !this.workflowService.selectedWorkflow.workflowId
      )
    }

    /** BUILD JOB */
    async buildJobScanAnother () {
      this.scanScreenService.showBuildJobAlert = false
      this.isProcessing = true
      this.processingLabel = 'SCANNING'
      this.commonService.updateView()
      await this.scanScreenService.scan()
      this.isProcessing = true
      this.commonService.updateView()
      this.isProcessing = false
      this.scanScreenService.showBuildJobAlert = true
    }

    async buildJobFinishJob () {
      try {
        this.scanScreenService.showBuildJobAlert = false
        this.isProcessing = true
        this.processingLabel = 'PROCESSING_AND_SENDING_INFO'
        if (this.scanScreenService.previewSettings) {
          this.previewBuildJob()
        } else {
          await this.scanScreenService.confirmUpload()
          this.completeWorkflow()
        }
      } catch (error) {
        this.handleError(error)
      }
    }
    cancelBuildJob () {
      this.persistService.setString(
        this.appSettings.buildJobScanProfileId,
        null
      )
      this.scanScreenService.cancelBuildJobAlertOptions.display = false
    }
    onDismissCancelJobAlert () {
      if (
        this.scanScreenService.cancelBuildJobAlertOptions.parent == 'preview'
      ) {
        this.scanScreenService.showSelectPreviewPopup = true
      } else if (
        this.scanScreenService.cancelBuildJobAlertOptions.parent == 'buildjob'
      ) {
        this.scanScreenService.showBuildJobAlert = true
      }
    }
    /**BUILD JOB */

      // Helper function to create a timeout
 async printWithTimeout(){
  // Create a timeout promise
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => reject(new Error('Operation timed out')), 180000);
  });

  // Race the operation against the timeout
  return Promise.race([this.scanScreenService.print(), timeoutPromise]);
}
  }
}
