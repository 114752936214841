/* --------------------------------------------------------------------------------------
  metaDataDecimal.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const metaDataDecimalComponent = {
    template: require("./metaDataDecimal.html"),
    bindings: {
        display: "=",
        data: "<",
        parentevent: "="
    },
    controllerAs: "vm",
    controller: class metaDataDecimalController {
        /* @ngInject */
        constructor(commonService, workflowService, $state, appSettings) {
            this.commonService = commonService;
            this.workflowService = workflowService;
            this.$state = $state;
            this.isRequired = false;
            this.appSettings = appSettings
        }

        $onInit() {
            this.isBlur = true;
            this.metadataType = this.data.type.toLowerCase();
            this.parentevent = {};
            this.setBindings();
            this.showNumPad = false;
            this.initKeypad();
        }

        openDecimalPadPopup() {
            this.showNumPad = true;
            this.initKeypad();
        }

        openJobSplitPopup() {
            this.displayJobSplitPopup = true;
            this.commonService.updateView();
        }
        closeJobSplitPopup() {
            this.displayJobSplitPopup = false;
            this.commonService.updateView();
        }

        onOk() {
            this.data.value = this.currencyValue;
            if (this.data.value) {
                this.isRequired = false;
            }
            this.showNumPad = false;
        }

        onCancel() {
            this.showNumPad = false;
        }

        showEnterNumberBanner() {
            angular.element('#enter-number-banner').xrxbanner('open');
        }
        showMaxNumberBanner() {
            angular.element('#maximum-number-banner').xrxbanner('open');
        }

        initKeypad() {
            setTimeout(() => {
                angular.element('#job-split-data-count-input').xrxkeyboard({
                    initialPlaceholder: "0.00",
                    layout: 'xrxNum-decimal',
                    maxLength: 8,
                    theme: 'blue',
                    appendLocally: false,
                    appendTo: '#job-split-data-count-keypad',
                    alwaysOpen: true,
                    backgroundSlide: false,
                    beforeVisible: (event, keyboard, el) => {
                        angular.element(".xrx-keyboard").css("position", "relative");
                        angular.element(".xrx-num-accept").css("visibility", "hidden");
                        angular.element(".xrx-num-cancel").css("visibility", "hidden");
                        angular.element(".xrx-keyboard").css("box-shadow", "none");
                        angular.element(".xrx-keyboard").css("border-top", "none");
                        angular.element(".xrx-keyboard").css("transition", "width 0s");
                        angular.element(".xrx-keyboard").css("padding-top", "0");
                        angular.element(".xrx-keyboard").css("padding-bottom", "0");
                    },
                    change: (event, keyboard, el) => {
                        //disable decimal if already clicked or if it would be the last character
                        if(angular.element("#job-split-data-count-input").val().includes('.') || angular.element("#job-split-data-count-input").val().length >= 7){
                            angular.element('.ui-keyboard-button[data-value="."]').addClass("disabled xrx-xrxbutton-disabled ui-state-disabled")
                        }
                        else{
                            angular.element('.ui-keyboard-button[data-value="."]').removeClass("disabled xrx-xrxbutton-disabled ui-state-disabled ui-state-hover")
                        }
                        this.currencyValue = angular.element("#job-split-data-count-input").val();
                    }
                });

                this.commonService.updateView();
            });
        }

        formatMetaData(num) {
            this.data.value = Math.round(num * 100) / 100;
        }

        setBindings() {
            this.parentevent.ctrl = this;
            this.parentevent.reset = this.resetDecimalType;
            this.parentevent.validateRequiredField = this.validateRequiredField;
        }

        resetDecimalType() {
            this.ctrl.isRequired = false;
            this.ctrl.data.value = '';
            this.ctrl.commonService.updateView();
        }

        validateRequiredField() {
            if (this.ctrl.data.isRequired && (!this.ctrl.data.value || this.ctrl.data.value == '')) {
                this.ctrl.isRequired = true;
            }
            return this.ctrl.isRequired;
        }

        onBlur() {
            this.isBlur = true;
            this.commonService.updateView();
        }
    }
};
