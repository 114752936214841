/* --------------------------------------------------------------------------------------
privacyStatement.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const privacyStatementComponent = {
  template: `
  <div>
    <div
      id="privacy-action-bar"
      class="xrx-action-bar"
    >
      <div class="xrx-action-bar-label">
        <div class="action-bar-label-text">
          {{ 'PRIVACY.TITLE' | translate }}
        </div>
      </div>
    </div>
    <div class="xmx-2 text-align-center full-screen">
    <div class="privacy-content xmx-2">
    <p class='xpt-2 purple'>{{ "PRIVACY.CONSENT_PROMPT" | translate }}</p>
    <p class='xpt-2 purple'>{{ "PRIVACY.CONSENT_PROMPT_ADDITIONAL" | translate }}</p>
  <div class="xpt-2">
  <button
    xw-button
    id="privacy-statement-close-button"
    ng-click="vm.onClose()"
    label="{{ 'PRIVACY.CLOSE' | translate }}"
  >
  </button>
  <button
  xw-button
  theme-primary
  id="privacyStatement-accept-button"
  ng-click="vm.onAccept()"
  label="{{ 'PRIVACY.ACCEPT' | translate }}"
>
</button>
      
          </div>
            </div>
            <div class='footer-btn'>
            <button
            xw-button
            id="privacyStatement-popup-button"
            class="view-privacy-statement-btn"
            ng-click="vm.openPrivacyStatementPopup()"
            label="{{ 'PRIVACY.VIEW_STATEMENT' | translate }}"
          >
          </button>
          </div>
        </div>
        <privacy-statement-popup
        popup-id="vm.popupId"
        display="vm.privacyStatementPopup"
        loading="vm.loadingPrivacyStatement"
        content="vm.privacyStatement"
        on-close="vm.closePrivacyStatementPopup()"
      ></privacy-statement-popup>
    `,
  controllerAs: 'vm',
  controller: class privacyStatementCtrl {
    /* @ngInject*/
    constructor (
      appSettings,
      commonService,
      $state,
      privacyStatementService,
      $sce
    ) {
      this.appSettings = appSettings
      this.commonService = commonService
      this.privacyStatementService = privacyStatementService
      this.$state = $state
      this.$sce = $sce
      this.popupId = "splash-screen"
    }

    $onInit () {
      this.screenSize = this.commonService.getScreenSize()
      this.display = true
    }

    onClose () {
      this.commonService.exitApp()
    }
    async onAccept () {
        this.$state.go('landing')
    }
    openPrivacyStatementPopup () {
      this.privacyStatementPopup = true
      this.loadingPrivacyStatement = true
      this.privacyStatementService
        .getPrivacyStatement(this.commonService.getNavigatorLanguage())
        .then(r => {
          this.privacyStatement = this.renderHTML(r.data)
          this.loadingPrivacyStatement = false
          setTimeout(async () => {
            angular.element('#privacy-statement-scroll').xrxscrollable({
              scroll: true,
              height: this.screenSize == 7 ? 390 : 504,
              width: this.screenSize == 7 ? 788 : 1006
            })
          })
          this.commonService.updateView()
        })
        .catch(err => {
          this.loadingPrivacyStatement = false
          this.privacyStatementPopup = false
          //set error here
          //setError(getErrorByStatusCode(err.response.status))
        })
      document.activeElement.blur()
      this.commonService.updateView()
    }

    closePrivacyStatementPopup () {
      document.activeElement.blur()
      this.privacyStatementPopup = false
      this.commonService.updateView()
    }
    renderHTML (html_code) {
      var decoded = angular.element('<textarea />').html(html_code).text()
      return this.$sce.trustAsHtml(decoded)
    }
  },
  bindings: {
    onOk: '&'
  }
}
