/* --------------------------------------------------------------------------------------
   scanSettingsSection.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const scanSettingsSectionComponent = {
  template: require('./scanSettingsSection.html'),
  bindings: {
    reset: '&'
  },
  controllerAs: 'vm',
  controller: class scanSettingsSectionController {
    /* @ngInject */
    constructor(commonService, scanScreenService, $state, appSettings, general, gcpService) {
      this.commonService = commonService
      this.scanScreenService = scanScreenService
      this.gcpService = gcpService
      this.$state = $state
      this.appSettings = appSettings
      this.general = general
    }
    $onInit() {
      this.screenSize = this.commonService.getScreenSize()
      this.backgroundSuppression = false;
      this.thirdGenBrowser = this.commonService.getBrowserType() == 3
    }

    onActionSwitch() {
      this.scanScreenService.emailSettings = !this.scanScreenService
        .emailSettings;
    }

    onAddNewEmailRecipientRowClick() {
      this.addNewEmailRecipientRowClicked = true;
      this.commonService.updateView();
      const keyboard = angular.element('#email-recipient-input').getkeyboard()
      if(!keyboard.isVisible()){
      setTimeout(() => {
        angular.element('#email-recipient-input').getkeyboard().reveal();
        }, 500);
      }
      
    }

    onAddNewEmailRecipientInputClose() {
      this.addNewEmailRecipientRowClicked = false;
      this.commonService.updateView();
    }

    onAddNewEmailRecipientInputClick() {
      this.addNewEmailRecipientRowClicked = true;
      this.commonService.updateView();
    }

    openAddNewRecipientPopup() {
      this.showAddRecipientPopup = true;
      this.commonService.updateView();

      setTimeout(() => {
        angular.element('#exported-data-recipient-input').getkeyboard().reveal();
      }, 500);
    }

    refreshBoundingBox() {
      setTimeout(async () => {
        angular.element("#scan-scanSettings-scroll").xrxscrollable({
          scroll: true,
          height: this.boundingBoxHeight,
          width: this.boundingBoxWidth,
        });
      });
    }

    onPrintRowClick() {
      this.scanScreenService.printSettings = !this.scanScreenService.printSettings;
      this.refreshBoundingBox();
    }

    async openResetBanner() {
      await this.reset()
      angular.element('#reset-banner').xrxbanner('call')
    }

    async openDisabledScanSettingBanner() {
      angular.element('#disabled-scan-setting-banner').xrxbanner('call')
    }

    onSwitchEmailSettings() {
      setTimeout(() => {
        this.refreshScroll();
      }, 500);
    }

    emailRecipientChanged() {
      this.validateForbiddenChars();
      this.scanScreenService.emailAddress = this.removeForbiddenChars(this.scanScreenService.emailAddress);
      angular.element('#email-recipient-input').val(this.scanScreenService.emailAddress)
    }

    validateForbiddenChars(){
      if(this.commonService.emailHasInvalidChars(this.scanScreenService.emailAddress)){
          angular.element('#email-forbidden-chars-banner').xrxbanner('call');
      }
  }

  removeForbiddenChars(email){
      let inputVal = angular.copy(email)
      this.general.emailAddressForbiddenChars.forEach(c=>{
          if(inputVal.includes(c)){
              inputVal = inputVal.replace(c,"")
          }
      })
      return inputVal;
  }

  
    closePrivacyStatementPopup() {
      document.activeElement.blur();
      this.privacyStatementPopup = false;
      this.commonService.updateView();
    }
  
    renderHTML(html_code)
    {
        var decoded = angular.element('<textarea />').html(html_code).text();
        return this.$sce.trustAsHtml(decoded);
    };

    onBgSuppressToggle(){
      this.scanScreenService.backgroundSuppressions = !this.scanScreenService.backgroundSuppression;
    }

    refreshScroll() {
      angular.element('#scan-scanSettings-container').xrxscrollable('refresh');
      this.commonService.updateView();
    }
  }
}
