/* --------------------------------------------------------------------------------------
   scanSettingsSection.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const scanSettingsComponent = {
    template: require('./scanSettings.html'),
    bindings: {
      display:'<',
      hideScanSettingsCallback: '&'
    },
    controllerAs: 'vm',
    controller: class scanSettingsController {
      /* @ngInject */
      constructor(commonService, scanScreenService, $state, appSettings) {
        this.commonService = commonService
        this.scanScreenService = scanScreenService
        this.$state = $state
        this.appSettings = appSettings
      }
      $onInit() {
        this.screenSize = this.commonService.getScreenSize()
        this.backgroundSuppression = false;
        this.thirdGenBrowser = this.commonService.getBrowserType() == 3
        this.refreshScroll();
      }
    $onChanges = function (changes) {
       if(changes.display.currentValue == true){
        this.initDraftScanSettings()
       }
       else{
        this.draftScanSettings = {};
       }
    };

      initDraftScanSettings(){
        this.draftScanSettings.resolution               = this.scanScreenService.resolution
        this.draftScanSettings.resolutionText           = this.scanScreenService.resolutionText
        this.draftScanSettings.resolutionValue          = this.scanScreenService.resolutionValue
        this.draftScanSettings.resolutionIndex          = this.scanScreenService.resolutionIndex

        this.draftScanSettings.outputColor              = this.scanScreenService.outputColor
        this.draftScanSettings.outputColorText          = this.scanScreenService.outputColorText 
        this.draftScanSettings.outputColorIndex         = this.scanScreenService.outputColorIndex
            
        this.draftScanSettings.twoSidedScan             = this.scanScreenService.twoSidedScan
        this.draftScanSettings.twoSidedScanText         = this.scanScreenService.twoSidedScanText
        this.draftScanSettings.twoSidedScanIndex        = this.scanScreenService.twoSidedScanIndex
            
        this.draftScanSettings.originalType             = this.scanScreenService.originalType
        this.draftScanSettings.originalTypeImage        = this.scanScreenService.originalTypeImage
        this.draftScanSettings.originalTypeText         = this.scanScreenService.originalTypeText
        this.draftScanSettings.originalTypeIndex        = this.scanScreenService.originalTypeIndex

        this.draftScanSettings.originalSize             = this.scanScreenService.originalSize
        this.draftScanSettings.originalSizeText         = this.scanScreenService.originalSizeText
        this.draftScanSettings.originalSizeOrientation  = this.scanScreenService.originalSizeOrientation
        this.draftScanSettings.originalSizeIndex        = this.scanScreenService.originalSizeIndex

        this.draftScanSettings.originalOrientation      = this.scanScreenService.originalOrientation
        this.draftScanSettings.originalOrientationText  = this.scanScreenService.originalOrientationText
        this.draftScanSettings.originalOrientationIndex = this.scanScreenService.originalOrientationIndex

        this.commonService.updateView();
        this.changeResolutionSelector();
        this.changeOutputColorSelector();
        this.changeTwoSidedScanSelector();
        this.changeOriginalTypeSelector();
        this.changeOriginalSizeSelector();
        this.changeOriginalOrientationSelector();
      }

      saveDraftSettings(){
        this.scanScreenService.resolution               = this.draftScanSettings.resolution           
        this.scanScreenService.resolutionText           = this.draftScanSettings.resolutionText 
        this.scanScreenService.resolutionValue          = this.draftScanSettings.resolutionValue
        this.scanScreenService.resolutionIndex          = this.draftScanSettings.resolutionIndex

        this.scanScreenService.outputColor              = this.draftScanSettings.outputColor
        this.scanScreenService.outputColorText          = this.draftScanSettings.outputColorText
        this.scanScreenService.outputColorIndex         = this.draftScanSettings.outputColorIndex

        this.scanScreenService.twoSidedScan             = this.draftScanSettings.twoSidedScan
        this.scanScreenService.twoSidedScanText         = this.draftScanSettings.twoSidedScanText
        this.scanScreenService.twoSidedScanIndex        = this.draftScanSettings.twoSidedScanIndex

        this.scanScreenService.originalType             = this.draftScanSettings.originalType
        this.scanScreenService.originalTypeImage        = this.draftScanSettings.originalTypeImage
        this.scanScreenService.originalTypeText         = this.draftScanSettings.originalTypeText
        this.scanScreenService.originalTypeIndex        = this.draftScanSettings.originalTypeIndex

        this.scanScreenService.originalSize             = this.draftScanSettings.originalSize
        this.scanScreenService.originalSizeText         = this.draftScanSettings.originalSizeText
        this.scanScreenService.originalSizeOrientation  = this.draftScanSettings.originalSizeOrientation   
        this.scanScreenService.originalSizeIndex        = this.draftScanSettings.originalSizeIndex

        this.scanScreenService.originalOrientation      = this.draftScanSettings.originalOrientation   
        this.scanScreenService.originalOrientationIndex = this.draftScanSettings.originalOrientationIndex
        this.scanScreenService.originalOrientationText  = this.draftScanSettings.originalOrientationText      
      }
  
  
      async openResetBanner() {
        await this.reset()
        angular.element('#reset-banner').xrxbanner('call')
      }
  
  
      onBgSuppressToggle(){
        this.scanScreenService.backgroundSuppressions = !this.scanScreenService.backgroundSuppression;
      }
  
      refreshScroll() {
          angular.element('#scanSettings-popup-container').xrxscrollable('refresh');
          this.commonService.updateView();
      }

      onOk(){
        this.saveDraftSettings();
        this.hideScanSettingsCallback()
        this.scanScreenService.showBuildJobAlert = true;
      }

      onCancel(){
        this.draftScanSettings = {};
        this.hideScanSettingsCallback()
        this.scanScreenService.showBuildJobAlert = true;
      }

    selectResolution (value) {
      this.draftScanSettings.resolution = this.scanScreenService.resolutionOptions[value.index].key
      this.draftScanSettings.resolutionText = value.label
      this.draftScanSettings.resolutionValue = this.scanScreenService.resolutionOptions[value.index].resolution
      this.draftScanSettings.resolutionIndex = value.index
      this.commonService.updateView()

      this.changeResolutionSelector()

      // TODO: If required to show banner for high resolution then comment out below lines
      // if (this.scanScreenService.resolution === "600") {
      //   angular.element("#high-resolution-banner").xrxbanner("open");
      // }
    }

    changeResolutionSelector () {
      angular
        .element('#scanSettings-resolution-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#scanSettings-resolution-selector-menu li')
        .eq(this.draftScanSettings.resolutionIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    selectOutputColor (value) {
      this.draftScanSettings.outputColor = this.scanScreenService.outputColorOptions[value.index].key
      this.draftScanSettings.outputColorText = value.label
      this.draftScanSettings.outputColorIndex = value.index
      this.commonService.updateView()

      this.changeOutputColorSelector()
    }

    changeOutputColorSelector () {
      angular
        .element('#scanSettings-output-color-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#scanSettings-output-color-selector-menu li')
        .eq(this.draftScanSettings.outputColorIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    selectTwoSidedScan (value) {
      this.draftScanSettings.twoSidedScan = this.scanScreenService.twoSidedScanOptions[value.index].key
      this.draftScanSettings.twoSidedScanText = value.label
      this.draftScanSettings.twoSidedScanIndex = value.index
      this.commonService.updateView()

      this.changeTwoSidedScanSelector()
    }

    changeTwoSidedScanSelector () {
      angular
        .element('#scanSettings-two-sided-scan-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#scanSettings-two-sided-scan-selector-menu li')
        .eq(this.draftScanSettings.twoSidedScanIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    selectOriginalType (value) {
      this.draftScanSettings.originalType = this.scanScreenService.originalTypeOptions[value.index].key
      this.draftScanSettings.originalTypeImage = this.scanScreenService.originalTypeOptions[value.index].img
      this.draftScanSettings.originalTypeText = value.label
      this.draftScanSettings.originalTypeIndex = value.index
      this.commonService.updateView()

      this.changeOriginalTypeSelector()
    }

    changeOriginalTypeSelector () {
      angular
        .element('#scanSettings-original-type-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#scanSettings-original-type-selector-menu li')
        .eq(this.draftScanSettings.originalTypeIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    selectOriginalSize (value) {
      const selectedSize = this.scanScreenService.originalSizeOptions[value.index]
      this.draftScanSettings.originalSize = selectedSize.key
      this.draftScanSettings.originalSizeText = selectedSize.value
      this.draftScanSettings.originalSizeOrientation = selectedSize.orientation
      this.draftScanSettings.originalSizeIndex = value.index
      this.commonService.updateView()

      this.changeOriginalSizeSelector()
    }

    changeOriginalSizeSelector () {
      angular
        .element('#scanSettings-original-size-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#scanSettings-original-size-selector-menu li')
        .eq(this.draftScanSettings.originalSizeIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    selectOriginalOrientation (value) {
      this.draftScanSettings.originalOrientation = this.scanScreenService.originalOrientationOptions[value.index].key
      this.draftScanSettings.originalOrientationText = value.label
      this.draftScanSettings.originalOrientationIndex = value.index
      this.commonService.updateView()
    }

    changeOriginalOrientationSelector () {
      angular
        .element('#scanSettings-original-orientation-selector-menu li')
        .removeClass('ui-state-focus ui-state-selected')
      angular
        .element('#scanSettings-original-orientation-selector-menu li')
        .eq(this.draftScanSettings.originalOrientationIndex)
        .addClass('ui-state-selected')
      this.commonService.updateView()
    }

    getOrientationGlyph (orientation) {
      switch (orientation) {
        case 'L':
          return 'glyphicon-short-edge-feed'
        case 'P':
          return 'glyphicon-long-edge-feed'
        default:
          return null
      }
    }

    async openDisabledScanSettingBanner(){
      angular.element('#scanSettings-popup-disabled-scansetting-banner').xrxbanner('call')
    }

    onScanSettingsPopupOpen(){
      setTimeout(() => {
        angular
          .element('#scanSettings-popup-container')
          .xrxscrollable({ scroll: true })
        this.commonService.updateView()
      }, 300)
  }
    }
  }
  