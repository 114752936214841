/* --------------------------------------------------------------------------------------
shellDataBag.service.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

const params = new URLSearchParams(window.location.search);
const keys = ["clientId", "appId", "deviceSN", "serverUrl", "key"];
const shellDataCookieName = "bexrx-shell-data-bag";

export class shellDataBagService {
  /* @ngInject */
  constructor(persistService, appSettings) {
    this.persistService = persistService;
    this.appSettings = appSettings;
  }
  setData() {
    const data = this.getParamObject();
    data.appName = this.appSettings.appName;
    this.persistService.setObject(this.getCookieName(), data, true);
  }
  getParamObject() {
    const result = {};
    keys.map((key) => (result[key] = params.get(key)));
    return result;
  }
  getData() {
    const data = this.persistService.getObject(this.getCookieName(), true);
    this.persistService.setString(this.getCookieName(), null, true);
    return data;
  }
  getCookieName() {
    return this.appSettings.assetsPrefix + "-" + shellDataCookieName;
  }
}
