export const buildJobAlert = {
  template: require('./buildJobAlert.html'),
  bindings: {
    display: '=',
    scanAnotherCallback: '&',
    finishJobCallback: '&'
  },
  controllerAs: 'vm',
  controller: class buildJobAlertController {
    /* @ngInject */
    constructor (commonService, scanScreenService, persistService, appSettings, $translate, $state) {
      this.commonService = commonService
      this.scanScreenService = scanScreenService
      this.persistService = persistService
      this.appSettings = appSettings
      this.translate = $translate
      this.$state = $state
    }

    $onInit () {
      this.screenSize = this.commonService.getScreenSize()
    }
    scanAnother(){
      this.scanAnotherCallback();
    }
    finishJob(){
      this.finishJobCallback();
    }
    changeScanSettings(){
      this.scanScreenService.showBuildJobAlert = false;
      this.displayScanSettingsAlert = true;
    }

    onCancelJobClick () {
      this.scanScreenService.cancelBuildJobAlertOptions = {
        parent: "buildjob",
        display:true
      };
      this.scanScreenService.showBuildJobAlert = false;
    }

    onHideScanSettings(){
      this.displayScanSettingsAlert = false;
    }
  }
}
