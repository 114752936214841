/* --------------------------------------------------------------------------------------
  project.service.js
  Copyright © 2021 Xerox Corporation. All Rights Reserved.

  Copyright protection claimed includes all forms and matters of copyrightable material
  and information now allowed by statutory or judicial law or hereinafter granted,
  including without limitation, material generated from the software programs which
  are displayed on the screen such as icons, screen display looks, etc.
  -------------------------------------------------------------------------------------
*/

export class projectService {
    /* @ngInject*/
    constructor(gcpService) {
      this.gcpService = gcpService;
      this.projects = [];
    }
  
    async getProjects() {
      this.projects = await this.gcpService.getProjects();
      return this.projects;
    }
  };
  