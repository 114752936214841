/* --------------------------------------------------------------------------------------
  metaDataEnum.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const metaDataEnumComponent = {
    template: require("./metaDataEnum.html"),
    bindings: {
        display: "=",
        data: "<",
        parentevent: '='
    },
    controllerAs: "vm",
    controller: class metaDataEnumController {
        /* @ngInject */
        constructor(commonService, workflowService, $state) {
            this.commonService = commonService;
            this.workflowService = workflowService;
            this.$state = $state;
            this.isRequired = false;
        }

        $onInit() {
            this.screenSize = this.commonService.getScreenSize();
            this.metadataType = this.data.type.toLowerCase();
            this.parentevent = {};
            this.setBindings();
            this.documentTypeOptions = [];
            this.testOptions = this.data.optionEnums;
            this.createOptions(this.testOptions);
            if (this.documentTypeOptions && this.documentTypeOptions.length > 0) {
                if (!this.data.value) {
                    this.data.value = this.documentTypeOptions[0].value;
                }
            }
            this.commonService.updateView();
        }

        createOptions(item) {
            for (let key in item) {
                this.documentTypeOptions.push({
                    key: key,
                    value: item[key]
                })
            }
            return this.documentTypeOptions;
        }

        setBindings() {
            this.parentevent.ctrl = this;
            this.parentevent.reset = this.resetEnumType;
            this.parentevent.validateRequiredField = this.validateRequiredField;
        }

        changeEnumOption(){
                angular
                  .element('#metadata_enum_type li.ui-menu-item')
                  .removeClass('ui-state-focus ui-state-selected')
                  
                  angular
                  .element('#metadata_enum_type li.ui-menu-item')
                  .eq(this.documentTypeOptions.findIndex(c=>c.value == this.data.value))
                  .addClass('ui-state-selected')
                this.commonService.updateView()
        }

        resetEnumType() {
            this.ctrl.isRequired = false;
            this.ctrl.data.value = this.ctrl.documentTypeOptions[0].value;
            this.ctrl.commonService.updateView();
            this.ctrl.changeEnumOption();
        }

        validateRequiredField() {
            if (this.ctrl.data.isRequired && (!this.ctrl.data.value || this.ctrl.data.value == '')) {
                this.ctrl.isRequired = true;
            }
            return this.ctrl.isRequired;
        }
        selectDocumentType(option) {
            this.data.value = this.documentTypeOptions[option.index].value;
            this.commonService.updateView();
            this.changeEnumOption();
        }
    }
};
