/* --------------------------------------------------------------------------------------
   xdaCalendar.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/
export const xdaCalendarComponent = {
  template: '<div id="xdaCalendar"></div>',
  bindings: {
    callback: '&',
    date: '=',
    click: '&',
    minDate: '<',
    maxDate: '<',
    dateFormat: '@',
    next: '&',
    prev: '&',
    reset: '='
  },
  controllerAs: 'vm',
  controller: class xdaCalendarComponent {
    /* @ngInject */
    constructor (moment, appSettings, commonService, $scope) {
      this.moment = moment
      this.appSettings = appSettings
      this.commonService = commonService
      this.glyphNextSpan = $(
        `<span class="xrx-button-glyph xrx-glyphicon glyphicon-navigate_right"></span>`
      )
      this.glyphPrevSpan = $(
        `<span class="xrx-button-glyph xrx-glyphicon glyphicon-navigate_left"></span>`
      )
      this.$scope = $scope;
    }
    $onInit () {
      this.screenSize = this.commonService.getScreenSize()
      console.log('date', this.date)
      this.buildCalendar(this.date)
      this.commonService.updateView()
      this.$scope.$watch(
        ()=>this.reset,
          (newValue, oldValue) =>{
            if (oldValue && newValue && !oldValue.isSame(newValue, 'second') ) {
                console.log('newValue', newValue)
                console.log('oldValue', oldValue)
                console.log('date reset', newValue)
                this.buildCalendar(newValue)
              }
          },
          true  // Setting this to true for deep watch
        );
    }

    buildCalendar(initialDate){
        const initDate = !initialDate || !this.moment.isMoment(initialDate) ? this.moment(new Date()) : initialDate;
        $('#xdaCalendar').pignoseCalendar({
            date: initDate.format('YYYY-MM-DD'),
            click: this.click,
            select: date => (this.date = date[0]),
            next: this.next,
            prev: this.prev
          })
          $('.pignose-calendar-top-nav.pignose-calendar-top-next').append(
            this.glyphNextSpan
          )
          $('.pignose-calendar-top-nav.pignose-calendar-top-prev').append(
            this.glyphPrevSpan
          )
    }
  }
}
