/* --------------------------------------------------------------------------------------
   previewPopup.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const previewPopupComponent = {
  template: require('./previewPopup.html'),
  bindings: {
    display: '=',
  },
  controllerAs: 'vm',
  controller: class previewPopupController {
    /* @ngInject */
    constructor(appSettings, scanScreenService, commonService) {
      this.appSettings = appSettings;
      this.scanScreenService = scanScreenService;
      this.commonService = commonService;
    }

    $onInit() {
      this.screenSize = this.commonService.getScreenSize();
      this.clientWidth = this.screenSize == 7 ? 790 : 864;
      this.clientHeight = this.screenSize == 7 ? 392 : 460;

      setTimeout(() => {
        this.imageWidth = angular.element('#zoom-image').width();
        this.imageHeight = angular.element('#zoom-image').width();
        if (!this.left == null) {
          this.left = (this.clientWidth - this.imageWidth) / 2;
          this.top = 0;
        } else {
          this.left = (this.clientWidth - this.imageWidth) / 2;
          this.top = 0;
          angular.element('#zoom-image').css('left', this.left);
          angular.element('#zoom-image').css('top', this.top);
          angular.element('#zoom-image').css('transform', 'none');
        }

        angular.element('#zoom-action-bar-zoom-out-button').xrxbutton({ disabled: true });

        this.commonService.updateView();
      }, 500);
    }

    getPrimaryBackground() {
      if (this.background == 'primary')
        return "xrx-background " + this.appSettings.primaryColor
    }

    close() {
      angular.element('#zoom-popup').xrxpopup('close');
    }

    onClose() {
      this.scanScreenService.showPreviewPopup = false;
      this.scanScreenService.showSelectPreviewPopup = true;
      this.commonService.updateView();
    }
  
    zoomOut() {
      angular.element('#zoom-action-bar-zoom-in-button').xrxbutton({ disabled: false });
      const containerHeight = angular.element('#zoom-image-container').height();
      if (containerHeight > this.imageHeight - 20) { // if image height is shorter than container height, then consider it as minimized size
        return;
      }
      const newHeight = Math.max(angular.element('#zoom-image').height() / 2, containerHeight);
      this.imageWidth = angular.element('#zoom-image').width() * newHeight / this.imageHeight;
      this.imageHeight = newHeight;
      angular.element('#zoom-image').width(this.imageWidth)
      angular.element('#zoom-image').height(this.imageHeight)
      if (!this.left == null) {
        this.left = (this.clientWidth - this.imageWidth) / 2
        this.top = 0
      } else {
        this.left = (this.clientWidth - this.imageWidth) / 2
        this.top = 0
        angular.element('#zoom-image').css('left', this.left)
        angular.element('#zoom-image').css('top', this.top)
        angular.element('#zoom-image').css('transform', 'none')
      }
      this.commonService.updateView()

      if (containerHeight >  this.imageHeight - 20) { // if image height is shorter than container height, then consider it as minimized size
        angular.element('#zoom-action-bar-zoom-out-button').xrxbutton({ disabled: true });
        this.commonService.updateView();
      }
    }

    zoomIn() {
      angular.element('#zoom-action-bar-zoom-out-button').xrxbutton({ disabled: false });
      const containerWidth = angular.element('#zoom-image-container').width();
      if (containerWidth < this.imageWidth - 20) { // if image height is shorter than container height, then consider it as minimized size
        return;
      }
      const newWidth = Math.min(angular.element('#zoom-image').width() * 2, containerWidth);
      this.imageHeight = angular.element('#zoom-image').height() * newWidth / this.imageWidth;
      this.imageWidth = newWidth;
      angular.element('#zoom-image').width(this.imageWidth)
      angular.element('#zoom-image').height(this.imageHeight)
      if (this.left == null) {
        this.left = (this.clientWidth - this.imageWidth) / 2
        this.top = 0
      } else {
        this.left = (this.clientWidth - this.imageWidth) / 2
        this.top = 0
        angular.element('#zoom-image').css('left', this.left)
        angular.element('#zoom-image').css('top', this.top)
        angular.element('#zoom-image').css('transform', 'none')
      }
      this.commonService.updateView()

      if (containerWidth < this.imageWidth + 10) { // if image height is shorter than container height, then consider it as minimized size
        angular.element('#zoom-action-bar-zoom-in-button').xrxbutton({ disabled: true });
        this.commonService.updateView();
      }
    }

    onTouchStart(e) {
      this.startPointX = e.offsetX
      this.startPointY = e.offsetY
      this.sliding = true
    }

    onTouchStop(e) {
      this.sliding = false
    }

    onSliding(e) {
      if (this.sliding) {
        if (this.clientWidth < this.imageWidth) {
          const maxX = 0
          const minX = this.clientWidth - this.imageWidth
          this.left = Math.max(Math.min(this.left + (e.offsetX - this.startPointX), maxX), minX)
          this.startPointX = e.offsetX
          angular.element('#zoom-image').css('left', this.left)
        }
        if (this.clientHeight < this.imageHeight) {
          const maxY = 0
          const minY = this.clientHeight - this.imageHeight
          this.top = Math.max(Math.min(this.top + (e.offsetY - this.startPointY), maxY), minY)
          this.startPointY = e.offsetY
          angular.element('#zoom-image').css('top', this.top)
        }
        this.commonService.updateView()
      }
    }

    onOpen() {
      this.imageWidth = angular.element('#zoom-image').width()
      this.imageHeight = angular.element('#zoom-image').height()
      angular.element('#zoom-image').height(this.clientHeight)
      this.imageWidth = this.imageWidth * angular.element('#zoom-image').height() / this.imageHeight
      angular.element('#zoom-image').width(this.imageWidth)
      this.imageHeight = this.imageHeight = angular.element('#zoom-image').height()
      this.left = (this.clientWidth - this.imageWidth) / 2
      this.top = 0
      angular.element('#zoom-image').css('left', this.left)
      angular.element('#zoom-image').css('top', this.top)
      this.commonService.updateView()
    }
  }
}
