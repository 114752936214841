/* --------------------------------------------------------------------------------------
landing.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const landingComponent = {
  template: `
      <activity display="vm.loading" label="{{ 'LOADING_GCP' | translate }}" on-close="vm.onLoadingClose()"></activity>
      <div xw-alert display="vm.maintenance" class="maintenance-indicator" close-button on-close="vm.onMaintenanceClose()">
        <div class="center-activity">
          <div xw-activity widget-size="xrx-large"></div>
        </div>
        <p class="white-text"><strong>{{ 'INSTALLING_APP_UPDATES' | translate }}</strong></p>
        <div class="error-description">
          <p class="activity-subtitle">{{ 'THIS_MAY_TAKE_SEVERAL_MINUTES' | translate }}</p>
          <p class="activity-subtitle">{{ 'CLOSE_ALERT_CONTINUE_WORKING' | translate }}</p>
        </div>
      </div>
      <div class="hidden-maintenance-override" ng-show="vm.maintenance" ng-click="vm.maintenanceOff()"></div>
      <error-message
        ng-if="vm.errorType"
        error-type="vm.errorType"
        on-close="vm.onErrorClose()"
      ></error-message>
  `,
  controllerAs: "vm",
  controller: class landingController {
    /* @ngInject */
    constructor(
      apiClientService,
      appSettings,
      shellDataBagService,
      gcpService,
      commonService,
      errorService,
      deviceInfoService,
      workflowService,
      projectService,
      webServiceStatusService,
      persistService,
      sessionService,
      $state,
    ) {
      this.apiClientService = apiClientService;
      this.shellDataBagService = shellDataBagService;
      this.gcpService = gcpService;
      this.commonService = commonService;
      this.deviceInfoService = deviceInfoService;
      this.errorService = errorService;
      this.workflowService = workflowService;
      this.projectService = projectService;
      this.persistService = persistService;
      this.webServiceStatusService = webServiceStatusService;
      this.sessionService = sessionService;
      this.$state = $state;
      this.appSettings = appSettings;
    }
    async $onInit() {
      this.loading = true;
      this.persistService.setString(this.appSettings.buildJobScanProfileId, null);
      //TODO: This should be finally moved to the shell 
      if (location.port !== "8080" || !this.appSettings.disableServiceStatusCheckInLocal) {
        const eipStatus = await this.webServiceStatusService.getStatus();

        if (eipStatus.some((s) => !s)) {
          const serviceStatusCustomErrorType = this.getServiceStatusError(eipStatus)
          this.setServiceStatusError(serviceStatusCustomErrorType)
          return;
        }
      }
      
      const isInMaintainanceMode = await this.getMaintenanceModeValue();
      this.loading = false;

      if (isInMaintainanceMode) {
        this.maintenance = true;
        this.onLoadingClose = () =>
          setInterval(() => this.getAvailability(), 3000);
      } else {
        this.onLoadingClose = async () => {
          //await this.deviceInfoService.getDeviceCapabilities();
          const params = this.shellDataBagService.getParamObject();
          if(!params.key || params.key == "null" || params.key == "undefined"){
            const apiKeyError = this.errorService.getApiKeyError();
            this.commonService.updateView();
            this.$state.go("error",{errorType: apiKeyError, onClose: ()=>this.exitApp()});
          }
          else{
            await this.login();
          }
        };
      }

      this.commonService.updateView();
    }

    async getServiceStatusError(eipStatus) {
      const keys = [];
      if (!eipStatus[0]) {
        keys.push("DEVICE_CONFIGURATION");
      }
      if (!eipStatus[1]) {
        keys.push("JOB_MANAGEMENT_EXTENSION");
      }
      if (!eipStatus[2]) {
        keys.push("SCAN_TEMPLATE_MANAGEMENT");
      }
      if (!eipStatus[3]) {
        keys.push("SCAN_EXTENSION");
      }
      keys.push("CONTACT_ADMINISTRATOR_TO_ENABLE2")
      return errorType = {
        titleKey: "EIP_SERVICES",
        keys: keys,
        exitApp: true,
      };
    }
    setServiceStatusError(serviceStatusError) {
      this.errorType = getServiceStatusError(eipStatus)
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      this.loading = false;
      this.commonService.updateView();
    }
    handlePwmError(){
      this.errorType = this.errorService.errorTypes.cannotConnectWithApi;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType)
      this.commonService.updateView()
    }
    async getAvailability() {
      const isInMaintainanceMode = await this.getMaintenanceModeValue();
      if (!isInMaintainanceMode) this.maintenanceOff();
    }
    maintenanceOff() {
      this.maintenance = false;
      this.onMaintenanceClose = async () => {
        await this.login();
        this.errorType = this.errorService.errorTypes.appConfiguredIncorrectly;
        this.commonService.updateView();
        // this.$state.go("workflow")
      };
    }
    async getMaintenanceModeValue() {
      const maintenanceConfig = await this.apiClientService.$http.get(
        "maintenance.json"
      );
      return maintenanceConfig.data.maintenanceMode;
    }
    async login() {
      try {
        this.shellDataBagService.setData();
        const shellData = this.shellDataBagService.getData();
        const { clientId, deviceSN, serverUrl: initialServerUrl, key } = shellData;        
        this.apiClientService.setApiUrl()


        const appId = '19F0E46D-511F-4987-BA82-2B466BE366AC';
        const splitServerUrl = initialServerUrl.split('://');
        const serverUrl = splitServerUrl.length > 1 ? splitServerUrl[1] : initialServerUrl;
        this.gcpService.clientId = clientId &&  clientId != "null" && clientId != "undefined"? clientId : "";
        this.gcpService.appId = appId;
        this.gcpService.deviceSN = deviceSN;
        this.gcpService.serverUrl = serverUrl && serverUrl != "null" && serverUrl != "undefined" ? serverUrl : "";
        this.gcpService.apiKey = key;

        // if clientId is set, use that as the project id and go straight to Workflow selection
        //Otherwise, go to the project selection screen
        if((this.gcpService.clientId && !this.gcpService.serverUrl) || (this.gcpService.serverUrl && !this.gcpService.clientId)){
          this.handleInitError(this.errorService.errorTypes.appConfiguredIncorrectly)
        }
        else if(this.gcpService.clientId && this.gcpService.serverUrl){
          this.loading = true;
          this.gcpService.useV1 = true;
          try{
            //this.workflowService.selectedProject = this.selectedProject;
            this.sessionAccessInfo = await this.sessionService.getDeviceAuthSessionData();
            try{
              await this.gcpService.postSession(
                this.gcpService.clientId,
                this.gcpService.appId,
                this.gcpService.deviceSN,
                this.sessionService.sessionAccessInfo.username ?? "",
                this.gcpService.serverUrl,
                this.gcpService.apiKey
              )
            }
            catch(error){
              this.loading = false;
              if(error.status !== 401){
                this.handlePwmError();
              }
              else{
                throw error;
              }
            }
            this.workflowService.showSelectWorkflow = true
            this.loading = false;
            this.$state.go('workflow')
          }
          catch (error){
            this.loading = false;
            this.handleInitError(error);
          }
        }
        else{
          this.gcpService.useV1 = false;
          this.$state.go("project");
        }
      } catch (error) {
        this.loading = false;
        this.handleInitError(error);
      }
    }
    exitApp() {
      this.commonService.exitApp();
    }
    handleInitError(error){
        let errorTypeByStatusCode  = this.errorService.getErrorByStatusCode(error.status);
        if(errorTypeByStatusCode == this.errorService.errorTypes.errorHasOccured){
          //no match by status code - throw custom error
          this.showCustomError(error);
        }
        else{
          
          this.errorType = errorTypeByStatusCode;
          this.onErrorClose = this.errorService.getErrorCallback(this.errorType)
          this.commonService.updateView();
        }
    }
    showCustomError(error){
      this.errorType = this.errorService.errorTypes.appConfiguredIncorrectly;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      this.commonService.updateView();
    }
  },
};
