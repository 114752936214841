export const cancelBuildJobAlert = {
    template: require('./cancelBuildJobAlert.html'),
    bindings: {
      display: '=',
      cancelJobCallback: '&',
      dismissCallback: '&'
    },
    controllerAs: 'vm',
    controller: class CancelBuildJobAlertController {
      /* @ngInject */
      constructor(commonService,$translate) {
        this.commonService = commonService;
        this.translate = $translate;
      }
  
      $onInit() {
        this.screenSize = this.commonService.getScreenSize();
      }

      dismissAlert(){
        this.display = false;
        this.dismissCallback();
      }

      cancelJob(){
        this.cancelJobCallback();
      }

    }
  }
  