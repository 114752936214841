/* --------------------------------------------------------------------------------------
   scan.service.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class scanService {
  /*@ngInject*/
  constructor(
    apiClientService,
    errorService,
    deviceInfoService,
    xeroxEIPParameters,
    workflowScanningService,
    sessionDataService,
    persistService,
    appSettings,
    $translate
  ) {
    this.apiClientService = apiClientService;
    this.errorService = errorService;
    this.deviceInfoService = deviceInfoService;
    this.xeroxEIPParameters = xeroxEIPParameters;
    this.workflowScanningService = workflowScanningService;
    this.sessionDataService = sessionDataService;
    this.persistService = persistService;
    this.$translate = $translate;
    this.appSettings = appSettings;
  }

   async getThumbnails(scanProfileId) {
    try {
      return await this.apiClientService.get(
        `/scan/preview/${scanProfileId}`
      );
    } catch (error) {
      throw error;
    }
  }


  async scan(data) {
    try {
      const scanTicket = await this.apiClientService.post("/scan/ticket", data);
      const scanJob = await this.workflowScanningService.submitScanTicket(
        scanTicket,
        this.sessionDataService.deviceUrl
      );
      let scanJobResp =  xrxScanV2ParseInitiateScanJob(scanJob.response);
      //if buildJob is enabled, save this scanTicket in persistService and use it for subsequent requests
      if(data.buildJob){
        this.persistService.setString(this.appSettings.buildJobScanProfileId,data.token);
      }
      return scanJobResp;
    } catch (error) {
      throw error;
    }
  }

  async completeUpload(scanProfileId, buildJob) {
    try {
      const response = await this.apiClientService.post(
        `/scan/${scanProfileId}/complete/${buildJob}`,
        {}
      );
      return response;
    } catch (error) {
     throw error;
    }
  }



  async confirmUpload(scanProfileId) {
    try {
      const response = await this.apiClientService.put(
        `/scan/${scanProfileId}`,
        {}
      );
      return response;
    } catch (error) {
     throw error;
    }
  }

  async getPageCount(scanProfileId) {
    try {
      const response = await this.apiClientService.get(
        `/scan/pages/${scanProfileId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getMaxSize() {
    try {
      const maxSize = await this.apiClientService.get("/scan/maxsize");
      return maxSize;
    } catch (error) {
      throw error;
    }
  }
  async getScanSize(scanProfileId) {
    try {
      const size = await this.apiClientService.get(
        `/scan/${scanProfileId}/length`
      );
      return size;
    } catch (error) {
      throw error;
    }
  }
}
